body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  color: #3d3d3d;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  user-select: none;
  font-size: 18px;
  line-height: 30px;
}

*{
  font-family: 'Roboto', sans-serif;
}

.d-flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ul {
  margin: 0;
  padding: 0;
  margin-top: 20px;
}

ul li {
  list-style-type: none;
  display: block;
  text-align: left;
  padding: 20px 0;
}

ul li a {
  color: #3d3d3d;
  text-decoration: none;
}

footer {
  background-color: #F5F6F8;
  padding: 40px 0;
}

.react-share__ShareButton{
  margin-right: 20px;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}
/* BASS GENERAL STYLES */
.container {
  max-width: 1200px;
  padding: 0 10px;
  margin: 0 auto;
}

.bass-logo{
  height: 80px;
}

.text-center{
  text-align: center;
}

.overlay::before, .overlay::after{
  display: none;
}

.slick-next, .slick-previous{
  background-color: red;
}

.slick-next {
  right: 0;
}

/* BASS HERO SECTION */
.bass-hero-bg{
  /* background-image: url('./assets/bg1.jpeg'), linear-gradient(#eb01a5, #d13531); */
  /* background-image: url("./assets/bg1.jpeg"), linear-gradient(red, yellow); */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.6),
    rgba(0,0,0, 0.6)
  ),url('./assets/b1.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: changeBackground 10s infinite ease-in-out;
}

.blog-borders{
  border-top: 2px solid #034b93;
  border-bottom: 2px solid #034b93;
  padding-bottom: 10px;
}

@keyframes changeBackground {
  0% {
    background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.6),
    rgba(0,0,0, 0.6)
  ),url('./assets/b1.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

  }
  20% {
    background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.6),
    rgba(0,0,0, 0.6)
  ),url('./assets/b2.jpeg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

  }
  40% {
    background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.6),
    rgba(0,0,0, 0.6)
  ),url('./assets/b3.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

  }
  80% {
    background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.6),
    rgba(0,0,0, 0.6)
  ),url('./assets/bg4.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

  }
  80% {
    background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.6),
    rgba(0,0,0, 0.6)
  ),url('./assets/bg1.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.bass-landing-page{
  padding: 30vh 0;
}

.landing-page-sub{
  color: #e8e8e8;
  width: 70%;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
}

.landing-page-header{
  font-size: 60px;
  line-height: 120%;
  font-weight: 800;
  margin: 0 0 20px;
  color: #F5F6F8;
}



.landing-page-cta, .landing-page-cta2{
  border: 0;
  outline: 0;
  margin-top: 20px;
  padding: 20px 35px;
  font-size: 16px;
  border-radius: 50px;
  background-color: #1775d1;
  color: #fff;
  font-weight: bold;
  transition: all .3s linear;
  cursor: pointer;
  display: flex;
  gap: 10px;
}

  .landing-page-cta2{
    background-color: #4caf50;
  }

.hiw-heading{
  font-weight: bold;
  color: #1775d1;
  font-size: 20px;
}

.b-container{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 1.5rem;
}

.b-child{
  background-color: #F5F6F8;
  border: 2px solid #F5F6F8;
  transition: all .3s ease-in;
  padding: 10px;
  border-radius: 20px;
}

.b-child img{
  border-radius: 15px;
}

.b-child:hover{
  border: 2px solid #1775d1;
  box-shadow: 0 0 40px #ddd; 
}

.b-child a {
  text-decoration: none;
  color: #3d3d3d;
}

.blog-title{
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  color: #034b93;
  text-transform: uppercase !important;
}

.read-more{
  background-color: #1775d1;
  text-decoration: none;
  color: #fff;
  font-size: 13px;
  /* padding: 20px 40px; */
  border-radius: 20px;
}

.blog-details{
  padding: 10px;
}

.b-summary{
  margin-top: 0;
  padding: 0;
}

.b-date{
  font-size: 14px;
}

.b-page-title{
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
}


/* BASS SERVICES SECTION */
.landing-our-services{
  padding: 30px 0;
}

.services-pill{
  background-color: #F1F1F1;
  padding: 10px 25px;
  color: #3d3d3d;
  border-radius: 20px;
  margin-right: 20px;
}

.services-heading{
  font-weight: bold;
  color: #1F2045;
  font-size: 24px;
}

.service-desc{
  font-weight: 400;
  margin-bottom: 20px ;
  font-size: 18px;
  line-height: 30px;
}

.hiw-text{
  color: #fff;
}

/* BASS HOW IT WORKS */
.bass-how-it-works{
  background-color: #1F2045;
  padding: 80px 0;
}

.bass-heading{
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
}

/* BASS ANSWERS SECTION */
.bass-answers-section{
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  color: #1F2045;
}

.how-it-works-container{
  display: flex;
  gap: 20px;
}

.how-it-works-card{
  width: 40%
}

.how-it-works-img{
  width: 60%
}

.mb-4{
  margin-bottom: 20px;
}


.hiw-number span{
  border-radius: 50%;
}

.how-it-works-child{
  width: 33%;
}

.how-it-works-child p{
  font-size: 20px !important;
  color: #fff;
  margin: 0;
  color: #1775d1;
  font-weight: bold;
}

.how-it-works-child div{
  color: #F5F6F8;
  font-weight: 300;
  width: 80%;
}

/* BASS OFFER CARD SECTION */
.bass-offer-card{
  margin: 0 auto;
  padding: 30px 60px;
  max-width: 1200px;
  display: flex;
  gap: 50px;
  align-items: center;
}

.bass-offer-card div{
  width: 50%;
  color: #fff;
}

.bass-offer-card h1{
  font-size: 35px;
  line-height: 40px;
}

.bass-offer-card-details{
  display: flex;
  align-items: center;
}

.bass-offer-card-info{
  width: 60%;
}

.bass-offer-card-info p{
  color: #1775d1;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  margin: 10px 0;
}

.bass-offer-card-button{
  padding: 20px;
  background-color: #1775d1;
  font-size: 1em;
  font-weight: 700;
  color: #fff;
  border: 0;
  outline: 0;
  width: 40%;
  transition: all .3s linear;
  border-radius: 50px;
  cursor: pointer;
}

.bass-offer-card-button:hover{
    background-color: #034b93;
}

.bass-offer-price-button{
  padding: 20px;
  background-color: #1775d1;
  font-size: 1em;
  font-weight: 700;
  color: #fff;
  border: 0;
  outline: 0;
  width: 40%;
  transition: all .3s linear;
  border-radius: 50px;
  cursor: pointer;
}

.bass-offer-price-button:hover{
    background-color: #034b93;
}

/* BASS FOOTER SECTION */
.bass-footer-wrapper{
  display: flex;
  gap: 20px;
}

.bass-footer-section{
  width: 25%;
}

.bass-footer-section p{
  font-weight: bold;
  color: #1F2045;
}

.bass-socials-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bass-socials-container span{
  background-color: #1775d1;
  color: #fff;
  padding: 8px;
  border-radius: 10px;
  font-size: 11px;
  color: white;
  animation: blink 2.5s linear infinite;
}

@keyframes blink{
  0%{opacity: 0.5;}
  50%{opacity: .8;}
  100%{opacity: 1;}
}

.bass-footer-links{
  color: #1775d1;
  display: block;
  margin-bottom: 10px;
}

.avatar-skeleton {
    width: 100%;
    height: 500px;
    line-height: 1;
    margin-bottom: 20px;
}

.blogbanner{
  height: 500px;
  width: 100%
}

.blog-card{
  width: 30%;
  border: 2px solid #000;
  border-radius: 10px;
  padding: 10px;
  transition: all .5s ease-in;
  background-color: #F5F6F8;
}

.blog-card img {
  height: 200px;
  width: 100%;
}

.blog-card:hover {
  background-color: #F5F6F8;
}

.big-blog-container{
  display: block !important;
}

  .small-blog-container{
    display: none !important;
  }

  .blog img{
    width: 100%;
  }

  .blog-wrapper{
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
  }

  .button-containers{ 
    display: flex; 
    align-items: center; 
    gap: 20px 
  }

  .bass-faq-container{
    padding: 70px 0;
  }

  .blog-post{
    font-size: 18px;
    line-height: 30px;
  }

  .blog-sides{
    padding: 0 20%;
  }

  .b-qualities{
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
  }

  .b-qualities .b-qualities-child{
    width: 50%;
  }
  
  .b-qualities .b-qualities-child p {
    font-size: 23px;
    color: #1F2045;
    font-weight: bold;
  }
  
  .b-qualities .b-qualities-child div {
    width: 80% !important;
  }

  .how-illustration{
    height: 50px;
  }

  .b-socials-section{
    background-color: #f1f1f1;
    padding: 70px 0;
  }

  .b-socials-section p {
    text-align: center;
  }

  .b-socials-platforms{
    display: flex;
    flex-wrap: wrap;
  }
  
  .b-socials-platforms a, .b-socials-platforms div{
    width: 20%;
    text-align: center;
    transition: transform 2s;
    text-decoration: none;
  }
  
  .b-socials-platforms a p, .b-socials-platforms div p{
    color: #1F2045;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }

  .b-socials-platforms a img, .b-socials-platforms div img {
    height: 125px;
  }
  
  .b-socials-platforms a span, .b-socials-platforms div span{
    color: #1775d1;
    margin: 0;
  }

  form {
    width: 100% !important;
  }

  .b-pricing-container{
    padding: 80px 0;
    color: #fff;
  }
  
  .b-pricing{
    margin: 0;
    padding-top: 80px;
    color: #fff;
  }

  .review{
    margin: 10px;
    background-color: #F5F6F8;
    border: 2px solid #F5F6F8;
    padding: 20px;
    border-radius: 20px;
  }

  .kXteup:hover:enabled, .kXteup:focus:enabled {
    color: #fff;
    background-color: #034b93 !important;
    box-shadow: 0 0 2px 0 #333;
  }

  .kXteup {
    background-color: none !important;
  }

  .Slxdj {
    background-color: none !important;
    }
  
  .b-pricing-container button{
    margin-bottom: 80px !important;
  }

  .b-pricing-container{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .b-pricing-features{
    padding-top: 50px;
  }

  .b-pricing-header{
    background-color: #1F2045;
    color: #fff;
  }
  .b-pricing-card p{
    font-size: 20px;
  }

  .b-pricing-card{
    background-color: #F1F1F1;
    margin: 20px;
    border-radius: 20px;
  }

  .b-pricing-card div{
    padding: 0 20px 30px;
    color: #3d3d3d;
  }
  .b-pricing-features p{
    padding: 0 20px;
    font-weight: 600;
    color: #000;
  }

  .pricing-button{
    border: 0;
    outline: 0;
    margin-top: 20px;
    padding: 20px 35px;
    font-size: 16px;
    border-radius: 50px;
    background-color: #4caf50;
    color: #fff;
    font-weight: bold;
    transition: all .3s linear;
    cursor: pointer;
    text-decoration: none;
  }

  .pricing-button span{
    margin-left: 5px;
    margin-top: -20px;
  }

  .w-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .w-child {
    background-color: #F5F6F8;
    border: 2px solid #F5F6F8;
    transition: all .3s ease-in;
    padding: 20px;
    border-radius: 20px;
  }

  .w-child p {
    font-weight: bold;
    color: #1F2045;
    font-size: 110%;
  }

  .mt-5{
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .cVXxbE {
    box-sizing: border-box;
    padding: 0;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    border: none;
    margin: 5px;
    background-color: #00000080 !important;
    font-size: 1.3em;
    content: "";
    height: 10px;
    width: 10px;
    box-shadow: 0 0 1px 3px #1775d1 !important;
    border-radius: 50%;
    outline: none;
}

.rec-dot_active{
  background-color: #1775d1 !important;
  border-color: 2px solid yellow !important;
}


  /* RESPONSIVENESS */
@media screen and (max-width: 760px) {
.bass-footer-wrapper{
    display: block;
    padding: 0 20px;
  }

  .bass-footer-section{
    width: 100%;
  }

  .bass-offer-card-details{
    display: block;
  }

  .bass-offer-card{
    display: block;
    margin-bottom: 0;
    padding: 30px;
  }

  .bass-offer-card div{
    width: 100%;
  }

  .bass-offer-card-button{
    margin: 20px 0 20px;
    width: 80%;
  }

  .how-it-works-container{
    display: block;
    padding: 0 20px;
  }

  .how-it-works-child{
    width: 100%;
  }

  .bass-landing-page{
    padding: 20vh 20px;
  }

  .landing-page-header{
    font-size: 32px;
  }

  .landing-page-sub{
    width: 100%;
    font-size: 16px;
  }

  .how-it-works-child div {
    width: 100%;
    margin-bottom: 40px;
  }

  .bass-answers-section{
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #1F2045;
  }

  .blogbanner{
    height: 180px;
    width: 100%
  }

  .avatar-skeleton {
    width: 100%;
    height: 150px;
    line-height: 1;
    margin-bottom: 20px;
  }

  .small-blog-container{
    display: block !important;
  }

  .blog-wrapper{
    display: block;
  }

  .blog-card{
    width: 100%;
    padding: 20px;
  }

  .big-blog-container{
    display: none !important;
  }
  
  .f-width{
    width: 100%;
  }

  .button-containers{
    display: block;
  }

  .b-container{
    display: grid;
    grid-template-columns: repeat(1,1fr);
  }

  .b-child{
    padding: 10px;
  }

  .blog-width{
    padding: 0 20px
  }

  .landing-our-services{
    margin: 0 10px;
  }
  .service-desc{
    font-weight: 400;
    margin-bottom: 20px ;
    font-size: 18px;
    line-height: 30px;
  }

  .bass-offer-card-button{
    margin: 0;
  }
  .bass-offer-price-button{
    padding: 20px;
    background-color: #1775d1;
    font-size: 1.2em;
    font-weight: 700;
    color: #fff;
    border: 0;
    outline: 0;
    width: 100%;
    transition: all .3s linear;
    border-radius: 50px;
    cursor: pointer;
  }


  .bass-footer-links{
    color: #1775d1;
    display: block;
    margin-bottom: 20px;
  }

  .b-sides{
    padding: 0 15px;
  }

  .blog-sides{
    padding: 0 20px;
  }

  .b-page-title{
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .b-blog-heading{
    font-size: 25px;
  }

  .b-qualities{
    display: block;
  }

  .b-qualities .b-qualities-child div {
    width: 100% !important;
  }

  .b-qualities .b-qualities-child{
    width: 100%;
  }

  .b-socials-platforms{
    display: flex;
    flex-wrap: wrap;
  }
  
  .b-socials-platforms a, .b-socials-platforms div{
    width: 50%;
    text-align: center;
    transition: transform 2s;
    text-decoration: none;
    margin-bottom: 20px;
  }
  
  .b-socials-platforms a p, .b-socials-platforms div p{
    color: #1F2045;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }

  .b-socials-platforms a img, .b-socials-platforms div img {
    height: 80px;
  }
  
  .b-socials-platforms a span, .b-socials-platforms div span{
    color: #1775d1;
    margin: 0;
  }

    .w-container{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .w-child{
    width: 80%;
    margin: 0 auto;
  }
  .review {
    margin: 0;
  }
  .rec-carousel{
    height: contain !important;
  }
}