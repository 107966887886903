nav{
    color: #fff;
    font-weight: normal;
    text-align: center;
    background-color: #F5F6F8;
}

.nav-img{
    height: 80px
}

.nav-alert-bar{
    color: #fff;
    background-color: #1775d1;
    margin: 0;
}

.nav-alert-content{
    padding: 10px;
    animation: blink 2s linear infinite;
}

.nav-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    color: #3d3d3d;
    max-width: 1200px;
    margin: auto;
}

.nav-contact-button{
  color: #3d3d3d;
  font-weight: bold;
  font-size: 16px;
  background: none;
  outline: 0;
  border: 0;
}

.nav-wrapper{
    position: relative;
}

.mobile-nav{
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    color: #3d3d3d !important;
    top: 0;
    z-index: 30;
    transition: all 30s ease-in-out ;
    padding-top: 60px;
}

.mobile-nav-wrapper{
    margin: 0 20px;
    height: 90vh;
}

.socials-container{
    display: relative;
    bottom: 0;
    right: 0;
    text-align: left;
    font-weight: bold;
}

@keyframes blink{
0%{opacity: 0;}
50%{opacity: .5;}
100%{opacity: 1;}
}

.mobile-menu-trigger{
    display: none;
}

.nav-blog{
    margin-right: 20px;
    color: #3d3d3d;
    cursor: pointer;
}

.nav-blog a {
    color: #3d3d3d;
    text-decoration: none;
}

@media screen and (max-width: 760px) {
    .nav-img{
        height: 50px
    }

    .nav-container{
        padding: 10px 20px;
    }

    .mobile-menu-trigger{
        display: block;
        margin-top: 8px;
    }

    .bass-offer-card-button{
        display: none;
    }

    .nav-blog{
        display: none;
    }
}