
.bass-accordion-container{
    display: flex;
    width: 80%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    outline: none;
    border: 0;
    border-bottom: 2px solid #1F2045;
    padding: 10px;
}

.bass-accordion-container:hover{
    cursor: pointer;
}

.bass-accordion-header{
    font-size: 18px;
    font-weight: bold;
    
}

.accordion__content{
    overflow: hidden;
    transition: max-height 0.6s ease;
    max-height: 0;
    width: 80%;
    margin: 0 auto;
    background-color: #F5F6F8;
    font-weight: 400;
}

.accordion__content div{
    padding: 40px;
    border-bottom: 2px solid #1F2045;    
    font-size: 18px;
    line-height: 30px;
}

@media screen and (max-width: 760px) {
.bass-accordion-container{
    width: 90%;
  }

  .accordion__content {
    width: 90%;
  }

  .accordion__content div{
    padding: 20px;
    border-bottom: 2px solid #1F2045;
  }

  .how-it-works-child p {
    font-size: 22px;
  }

  .how-it-works-child div {
    font-size: 18px;
    line-height: 30px;
  }

  .bass-accordion-header{
    font-size: 18px;
    text-align: left;
  }
}