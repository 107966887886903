.modal{
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .8);
    /* margin: 0 auto; */
}

.modal-content{
    max-width: 500px;
    border-radius: 20px;
    display: flex;
    margin: 0 auto;
    margin-top: 20vh;
    padding: 20px;
    background: #fff;
    gap: 30px;
}

.bass-input, textarea{
    /* display: block; */
    width: 93%;
    margin: 5px 0 10px;
    padding: 10px;
    border-radius: 8px;
    border: 1.5px solid #ddd;
    outline: none;
    transition: border .4s ease-in;
    color: #5f5f5f;
    resize: none;
}

.bass-input:focus, textarea:focus{
    border: 1.5px solid #1775d1;
}

label{
    font-weight: bold;
    margin-top: 10px;
    color: #a0a0a0;
}

@media screen and (max-width: 768px) {
    .modal-content{
        max-width: 80%;
    }
}